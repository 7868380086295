<template>
  <div>
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">投标项目维护</a>
      <label>></label>
      <a href="###">项目详情</a>
    </div>

    <div class="wrap-form">
      <div class="formeach">
        <div class="title">项目信息</div>
        <el-form label-width="120px">
          <el-form-item label="项目名称">{{
            bidProjectList.name
          }}</el-form-item>
          <el-form-item label="招标机构">{{
            bidProjectList.bidOrg
          }}</el-form-item>
          <el-form-item label="招标代理机构">{{
            bidProjectList.purchaseOrg
          }}</el-form-item>
          <el-form-item label="招标方机构代码">{{
            bidProjectList.bidCode
          }}</el-form-item>
          <el-form-item label="招标金额"
            >{{ bidProjectList.bidMoney | currency }} 万元</el-form-item
          >
          <el-form-item label="开标时间">{{ this.bidDate }}</el-form-item>
          <el-form-item label="保证金金额"
            >{{ bidProjectList.marginMoney | currency }} 万元</el-form-item
          >
          <el-form-item label="标的状态">
            {{
              {
                0: "投标中",
                1: "中标",
                2: "未中标",
                3: "中标待审核",
                4: "中标审核未通过",
              }[bidProjectList.bidStatus]
            }}</el-form-item
          >
          <el-form-item label="招标文件">
            <a :href="handleTo(bidProjectList.bidFile)" target="_blank">{{
              bidProjectList.bidFile ? "招标文件附件" : "无"
            }}</a>
            <el-button
              type="text"
              v-show="bidProjectList.bidFile"
              @click="downloadFile(bidProjectList.bidFile, '招标文件附件')"
              >下载</el-button
            >
          </el-form-item>
          <el-form-item label="招标项目网址">{{
            bidProjectList.scale
          }}</el-form-item>
          <template v-if="bidProjectList.bidStatus === 1">
            <el-form-item label="中标编号">{{
              bidProjectList.bidNum
            }}</el-form-item>
            <el-form-item label="合同金额"
              >{{ bidProjectList.contractMoney | currency }} 万元</el-form-item
            >
            <el-form-item label="中标时间">{{ this.winDate }}</el-form-item>
            <el-form-item label="中标通知书">
              <a :href="handleTo(bidProjectList.bidNotice)" target="_blank">{{
                bidProjectList.bidNotice ? "中标通知书附件" : "无"
              }}</a>
              <el-button
                type="text"
                v-show="bidProjectList.bidNotice"
                @click="
                  downloadFile(bidProjectList.bidNotice, '中标通知书附件')
                "
                >下载</el-button
              >
            </el-form-item>
            <el-form-item label="项目合同">
              <a :href="handleTo(bidProjectList.contract)" target="_blank">{{
                bidProjectList.contract ? "合同附件" : "无"
              }}</a>
              <el-button
                type="text"
                @click="downloadFile(bidProjectList.contract, '项目合同附件')"
                >下载</el-button
              >
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div class="formeach" v-if="this.marginLoanList">
        <div class="title">投标保证金贷款</div>
        <el-form label-width="120px">
          <el-form-item label="贷款总额度"
            >{{ this.accTotalLoan | currency }} 万元
          </el-form-item>
          <el-form-item label="在途贷款总额"
            >{{ this.quota | currency }} 万元</el-form-item
          >
          <el-form-item label="投标保证金"
            >{{ marginLoanList.margin | currency }} 万元
          </el-form-item>
          <el-form-item label="批复借款额度" v-if="marginLoanList.status >= 4"
            >{{ marginLoanList.auditMoney | currency }} 万元
          </el-form-item>
          <el-form-item label="最终批复金额" v-if="marginLoanList.status >= 4"
            >{{ marginLoanList.finalMoney | currency }} 万元
          </el-form-item>
          <el-form-item label="保证金状态">
            {{
              {
                0: "草稿",
                1: "待审核",
                2: "通过",
                3: "不通过",
                4: "已放款",
                5: "还款中",
                6: "已还款",
              }[marginLoanList.status]
            }}</el-form-item
          >
          <el-form-item
            label="保证金贷款合同"
            v-if="marginLoanList.status >= 4"
          >
            <a :href="handleTo(marginLoanList.signContract)" target="_blank">{{
              marginLoanList.signContract ? "保证金贷款合同附件" : "无"
            }}</a>
            <el-button
              type="text"
              @click="
                downloadFile(marginLoanList.signContract, '保证金贷款合同附件')
              "
              >下载</el-button
            >
          </el-form-item>
          <el-form-item label="放款凭证" v-if="marginLoanList.status >= 4">
            <a :href="handleTo(marginLoanList.payCert)" target="_blank">{{
              marginLoanList.payCert ? "放款凭证附件" : "无"
            }}</a>
            <el-button
              type="text"
              @click="downloadFile(marginLoanList.payCert, '放款凭证附件')"
              >下载</el-button
            >
          </el-form-item>
          <template v-if="marginLoanList.status === 6">
            <el-form-item label="还款金额"
              >{{ marginLoanList.returnMoney | currency }} 万元
            </el-form-item>
            <el-form-item label="还款时间">{{
              this.margin_returnDate
            }}</el-form-item>
            <el-form-item label="交易流水号"
              >{{ marginLoanList.transNum }}
            </el-form-item>
            <el-form-item label="还款凭证">
              <a :href="handleTo(marginLoanList.returnCert)" target="_blank">{{
                marginLoanList.returnCert ? "还款凭证附件" : "无"
              }}</a>
              <el-button
                type="text"
                @click="downloadFile(marginLoanList.returnCert, '还款凭证附件')"
                >下载</el-button
              >
            </el-form-item>
          </template>
        </el-form>
      </div>

      <div class="formeach" v-if="this.goodsMoneyLoanAndList">
        <div class="title">采购金贷款</div>
        <el-form label-width="120px">
          <el-form-item label="贷款总额度"
            >{{ this.accTotalLoan | currency }} 万元
          </el-form-item>
          <el-form-item label="在途贷款总额"
            >{{ this.quota | currency }} 万元</el-form-item
          >
          <el-form-item label="中标金额"
            >{{ goodsMoneyLoanAndList.googsMoney | currency }} 万元
          </el-form-item>
          <el-form-item label="回款周期"
            >{{ goodsMoneyLoanAndList.returnPeriod }} 天</el-form-item
          >
          <el-form-item label="贷款金额"
            >{{ goodsMoneyLoanAndList.loanMoney | currency }} 万元
          </el-form-item>
          <el-form-item label="供货方公司">{{
            goodsMoneyLoanAndList.productCompany
          }}</el-form-item>
          <el-form-item
            label="批复贷款额度"
            v-if="goodsMoneyLoanAndList.status >= 4"
            >{{ goodsMoneyLoanAndList.auditMoney | currency }} 万元
          </el-form-item>
          <el-form-item
            label="最终批复金额"
            v-if="goodsMoneyLoanAndList.status >= 4"
            >{{ goodsMoneyLoanAndList.finalMoney | currency }} 万元
          </el-form-item>
          <el-form-item label="贷款状态">
            {{
              {
                0: "草稿",
                1: "待审核",
                2: "通过",
                3: "不通过",
                4: "已放款",
                5: "还款中",
                6: "已还款",
              }[goodsMoneyLoanAndList.status]
            }}</el-form-item
          >
          <el-form-item
            label="货物贷款合同"
            v-if="goodsMoneyLoanAndList.status >= 4"
          >
            <a
              :href="handleTo(goodsMoneyLoanAndList.signContract)"
              target="_blank"
              >{{
                goodsMoneyLoanAndList.signContract ? "货物贷款合同附件" : "无"
              }}</a
            >
            <el-button
              type="text"
              @click="
                downloadFile(
                  goodsMoneyLoanAndList.signContract,
                  '货物贷款合同附件'
                )
              "
              >下载</el-button
            >
          </el-form-item>
          <el-form-item
            label="放款凭证"
            v-if="goodsMoneyLoanAndList.status >= 4"
          >
            <a
              :href="handleTo(goodsMoneyLoanAndList.payCert)"
              target="_blank"
              >{{ goodsMoneyLoanAndList.payCert ? "放款凭证附件" : "无" }}</a
            >
            <el-button
              type="text"
              @click="
                downloadFile(goodsMoneyLoanAndList.payCert, '放款凭证附件')
              "
              >下载</el-button
            >
          </el-form-item>
          <template v-if="goodsMoneyLoanAndList.status === 6">
            <el-form-item label="还款金额"
              >{{ goodsMoneyLoanAndList.returnMoney | currency }} 万元
            </el-form-item>
            <el-form-item label="还款时间">{{
              this.goods_returnDate
            }}</el-form-item>
            <el-form-item label="交易流水号"
              >{{ goodsMoneyLoanAndList.transNum }}
            </el-form-item>
            <el-form-item label="还款凭证">
              <a
                :href="handleTo(goodsMoneyLoanAndList.returnCert)"
                target="_blank"
                >{{
                  goodsMoneyLoanAndList.returnCert ? "还款凭证附件" : "无"
                }}</a
              >
              <el-button
                type="text"
                @click="
                  downloadFile(goodsMoneyLoanAndList.returnCert, '还款凭证附件')
                "
                >下载</el-button
              >
            </el-form-item>
          </template>
        </el-form>
        <div
          class="goodsMoneyLoanDetail_table"
          v-if="goodsMoneyLoanDetail.length >= 0"
        >
          <el-table class="qh-table" :data="goodsMoneyLoanDetail">
            <el-table-column label="产品">
              <template slot-scope="scope">
                <span>{{ scope.row.productName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="价格">
              <template slot-scope="scope">
                <span>{{ scope.row.productPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数量">
              <template slot-scope="scope">
                <span>{{ scope.row.productAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="总价">
              <template slot-scope="scope">
                <span
                  >{{
                    ((Number(scope.row.productAmount) *
                      Number(scope.row.productPrice)) /
                      10000)
                      | currency
                  }}
                  万元
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div style="text-align: center; margin-top: 69px">
        <el-button @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/formatDate";
export default {
  data() {
    return {
      bidProjectList: {},
      marginLoanList: {},
      goodsMoneyLoanAndList: {},
      goodsMoneyLoanDetail: [],
      winDate: "",
      bidDate: "",
      margin_returnDate: "",
      goods_returnDate: "",
      quota: "",
      accTotalLoan: "",
    };
  },
  mounted() {
    this.getBidProjectList(this.$route.params.id);
    this.getData();
  },
  methods: {
    getData() {
      this.$axios.get("/member/enterprisePage").then((res) => {
        if (res.state == 200) {
          this.quota = res.data.quota - res.data.usableQuota;
          this.accTotalLoan = res.data.accTotalLoan;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getBidProjectList(id) {
      this.$axios.get(`/bidProject/bidProjectDetails/${id}`).then((res) => {
        if (res.state == 200) {
          this.bidProjectList = res.data.bidProject;
          this.winDate = formatDate(res.data.bidProject.winDate);
          this.bidDate = formatDate(res.data.bidProject.bidDate);
          this.marginLoanList = res.data.marginLoan;
          this.margin_returnDate =
            res.data.marginLoan == null
              ? ""
              : formatDate(res.data.marginLoan.returnDate);
          this.goods_returnDate =
            res.data.goodsMoneyLoanAndList.goodsMoneyLoan == null
              ? ""
              : formatDate(
                  res.data.goodsMoneyLoanAndList.goodsMoneyLoan.returnDate
                );
          this.goodsMoneyLoanAndList =
            res.data.goodsMoneyLoanAndList.goodsMoneyLoan;
          this.goodsMoneyLoanDetail =
            res.data.goodsMoneyLoanAndList.goodsMoneyLoanDetail;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    downloadFile(path, filename) {
      let exts = path.split(".");
      let ext = exts[exts.length - 1];
      this.$axios
        .get("/download", {
          params: {
            path: path,
          },
          responseType: "blob",
        })
        .then((res) => {
          let link = document.createElement("a");
          let fileObj = URL.createObjectURL(res);
          link.download = filename + "." + ext;
          link.href = fileObj;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(fileObj);
        });
    },
    cancel() {
      this.$router.back();
    },
    handleTo(url) {
      return url;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-form {
  width: 941px;
  border: 1px solid #ededed;
  padding: 30px 40px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .formeach {
    margin-bottom: 20px;
    .title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 10px;
      border-bottom: 1px solid #ededed;
      padding-bottom: 6px;
    }
    .el-form {
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        width: 50%;
        // height: 25px;
        padding-right: 50px;
        a {
          text-decoration: none;
          color: #3883ff;
          margin-right: 20px;
        }
      }
    }
    .goodsMoneyLoanDetail_table {
      span {
        font-size: 16px;
      }
    }
  }
}
</style>